<template>
  <div class="row">
    <div class="col-md-4">
      <div class="panel">
        <div class="panel-heading">
          <h3 class="panel-title bold">Rekam Medik</h3>
        </div>
        <div class="panel-body">
          <div class="row">
            <table class="table">
              <tr>
                <td>Kode Register</td>
                <td style="width: 5px">:</td>
                <td>{{ detail.kode_register }}</td>
              </tr>
              <tr>
                <td>No. Rekam Medik</td>
                <td>:</td>
                <td>{{ detail.no_rekam_medik }}</td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>:</td>
                <td>{{ detail.nama }}</td>
              </tr>
              <tr>
                <td>Tanggal, Jam</td>
                <td>:</td>
                <td>{{ formatIndo(detail.tanggal) }}, {{ detail.jam }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="panel-footer">
          <a
            href="javascript:window.history.go(-1);"
            class="btn btn-default mr-2"
            >Kembali</a
          >
          <button
            @click="setTunda(detail.id_antrian)"
            class="btn btn-warning mr-2"
          >
            Tunda
          </button>
          <button
            @click="setLab(detail.id_antrian)"
            class="btn btn-warning mr-2"
          >
            Laboratorium
          </button>
        </div>
      </div>
      <!-- <div class="btn btn-block btn-default text-left">
        <i class="fa fa fa-check-square"></i>
        Anamnesis
      </div> -->
    </div>
    <div class="col-md-8">
      <form @submit.prevent="postData" enctype="multipart/form-data">
        <div id="accordion" class="accordion">
          <div class="card">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(1)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              Anamnesis
            </div>
            <div :class="{ show: openAccordion === 1 }" class="collapse">
              <div class="card-body">
                <div class="container">
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="keluhan_utama"
                      >Keluhan Utama</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.keluhan_utama"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.keluhan_utama,
                        }"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="riwayat_penyakit"
                      >Riwayat Penyakit</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.riwayat_penyakit"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.riwayat_penyakit,
                        }"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="riwayat_alergi"
                      >Riwayat Alergi</label
                    >
                    <div class="col-md-9">
                      <input
                        list="listAlergi"
                        v-model="form.riwayat_alergi"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.riwayat_alergi,
                        }"
                      />
                      <datalist id="listAlergi">
                        <option value="">Pilih</option>
                        <option value="Obat">Obat</option>
                        <option value="Makanan">Makanan</option>
                        <option value="Udara">Udara</option>
                      </datalist>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="riwayat_pengobatan"
                      >Riwayat Pengobatan</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.riwayat_pengobatan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.riwayat_pengobatan,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(2)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              {{
                this.userAccess == "igd"
                  ? "Asesmen awal IGD"
                  : "Pemeriksaan Fisik"
              }}
            </div>
            <div :class="{ show: openAccordion === 2 }" class="collapse">
              <div class="card-body">
                <div class="container">
                  <div v-if="this.userAccess == 'igd'">
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="asesmen_nyeri"
                        >Asesmen nyeri</label
                      >
                      <div class="col-md-9">
                        <select
                          v-model="form.asesmen_nyeri"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.asesmen_nyeri,
                          }"
                        >
                          <option value="Ada">Ada</option>
                          <option value="Tidak Ada">Tidak Ada</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="skala_nyeri"
                        >Skala nyeri</label
                      >
                      <div class="col-md-9">
                        <ul class="pagination">
                          <li
                            class="page-item text-center"
                            v-for="skala in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                            :key="skala"
                            :class="{ active: form.skala_nyeri === skala }"
                            style="min-width: 40px"
                          >
                            <span class="page-link" @click="setNyeri(skala)">{{
                              skala
                            }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="lokasi_nyeri"
                        >Lokasi nyeri</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          v-model="form.lokasi_nyeri"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.lokasi_nyeri,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="penyebab_nyeri"
                        >Penyebab nyeri</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          v-model="form.penyebab_nyeri"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.penyebab_nyeri,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="durasi_nyeri"
                        >Durasi nyeri</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          v-model="form.durasi_nyeri"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.durasi_nyeri,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="frekuensi_nyeri"
                        >Frekuensi nyeri</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          v-model="form.frekuensi_nyeri"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.frekuensi_nyeri,
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group row m-b-15">
                      <label
                        class="col-md-3 col-form-label push-right"
                        for="kajian_risiko_jatuh"
                        >Kajian Risiko jatuh</label
                      >
                      <div class="col-md-9">
                        <select
                          v-model="form.kajian_risiko_jatuh"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.kajian_risiko_jatuh,
                          }"
                        >
                          <option value="0 - 24 (risiko rendah)">
                            0 - 24 (risiko rendah)
                          </option>
                          <option value="25 - 44 (risiko sedang)">
                            25 - 44 (risiko sedang)
                          </option>
                          <option value="> 45 (risiko tinggi)">
                            > 45 (risiko tinggi)
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="keadaaan_umum"
                      >Keadaan Umum</label
                    >
                    <div class="col-md-9">
                      <select
                        v-model="form.keadaaan_umum"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.keadaaan_umum,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option value="Baik">Baik</option>
                        <option value="Sedang">Sedang</option>
                        <option value="Lemah">Lemah</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="tingkat_kesadaran"
                      >Tingkat kesadaran</label
                    >
                    <div class="col-md-9">
                      <select
                        v-model="form.tingkat_kesadaran"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.tingkat_kesadaran,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option value="Sadar Baik/Alert : 0">
                          Sadar Baik/Alert : 0
                        </option>
                        <option value="Berespon dengan  kata-kata/Voice : 1">
                          Berespon dengan kata-kata/Voice : 1
                        </option>
                        <option
                          value="Hanya berespons  jika dirangsang  nyeri/pain : 2"
                        >
                          Hanya berespons jika dirangsang nyeri/pain : 2
                        </option>
                        <option value="Pasien tidak  sadar/unresponsive  : 3">
                          Pasien tidak sadar/unresponsive : 3
                        </option>
                        <option value="Gelisah atau  bingung : 4">
                          Gelisah atau bingung : 4
                        </option>
                        <option value="Acute Confusional  States : 5">
                          Acute Confusional States : 5
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="riwayat_pengobatan"
                      >Vital sign</label
                    >
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.denyut_jantung"
                            type="text"
                            class="form-control"
                            placeholder="Denyut jantung"
                            :class="{
                              'is-invalid': formValidate.denyut_jantung,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.pernapasan"
                            type="text"
                            class="form-control"
                            placeholder="Pernapasan"
                            :class="{
                              'is-invalid': formValidate.pernapasan,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.sistole"
                            type="int"
                            class="form-control"
                            placeholder="*Sistole"
                            :class="{
                              'is-invalid': formValidate.sistole,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.diastole"
                            type="int"
                            class="form-control"
                            placeholder="*Diastole"
                            :class="{
                              'is-invalid': formValidate.diastole,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.suhu_tubuh"
                            type="text"
                            class="form-control"
                            placeholder="Suhu tubuh"
                            :class="{
                              'is-invalid': formValidate.suhu_tubuh,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.kepala"
                            type="text"
                            class="form-control"
                            placeholder="Kepala"
                            :class="{
                              'is-invalid': formValidate.kepala,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.mata"
                            type="text"
                            class="form-control"
                            placeholder="Mata"
                            :class="{
                              'is-invalid': formValidate.mata,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.telinga"
                            type="text"
                            class="form-control"
                            placeholder="Telinga"
                            :class="{
                              'is-invalid': formValidate.telinga,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.hidung"
                            type="text"
                            class="form-control"
                            placeholder="Hidung"
                            :class="{
                              'is-invalid': formValidate.hidung,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.rambut"
                            type="text"
                            class="form-control"
                            placeholder="Rambut"
                            :class="{
                              'is-invalid': formValidate.rambut,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.bibir"
                            type="text"
                            class="form-control"
                            placeholder="Bibir"
                            :class="{
                              'is-invalid': formValidate.bibir,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.gigi_geligi"
                            type="text"
                            class="form-control"
                            placeholder="Gigi Geligi"
                            :class="{
                              'is-invalid': formValidate.gigi_geligi,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.lidah"
                            type="text"
                            class="form-control"
                            placeholder="Lidah"
                            :class="{
                              'is-invalid': formValidate.lidah,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.langit_langit"
                            type="text"
                            class="form-control"
                            placeholder="Langit-langit"
                            :class="{
                              'is-invalid': formValidate.langit_langit,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.leher"
                            type="text"
                            class="form-control"
                            placeholder="Leher"
                            :class="{
                              'is-invalid': formValidate.leher,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.tenggorokan"
                            type="text"
                            class="form-control"
                            placeholder="Tenggorokan"
                            :class="{
                              'is-invalid': formValidate.tenggorokan,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.tonsil"
                            type="text"
                            class="form-control"
                            placeholder="Tonsil"
                            :class="{
                              'is-invalid': formValidate.tonsil,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.dada"
                            type="text"
                            class="form-control"
                            placeholder="Dada"
                            :class="{
                              'is-invalid': formValidate.dada,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.payudara"
                            type="text"
                            class="form-control"
                            placeholder="Payudara"
                            :class="{
                              'is-invalid': formValidate.payudara,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.punggung"
                            type="text"
                            class="form-control"
                            placeholder="Punggung"
                            :class="{
                              'is-invalid': formValidate.punggung,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.perut"
                            type="text"
                            class="form-control"
                            placeholder="Perut"
                            :class="{
                              'is-invalid': formValidate.perut,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.genital"
                            type="text"
                            class="form-control"
                            placeholder="Genital"
                            :class="{
                              'is-invalid': formValidate.genital,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.anus_dubur"
                            type="text"
                            class="form-control"
                            placeholder="Anus/Dubur"
                            :class="{
                              'is-invalid': formValidate.anus_dubur,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.lengan_atas"
                            type="text"
                            class="form-control"
                            placeholder="Lengan Atas"
                            :class="{
                              'is-invalid': formValidate.lengan_atas,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.lengan_bawah"
                            type="text"
                            class="form-control"
                            placeholder="Lengan Bawah"
                            :class="{
                              'is-invalid': formValidate.lengan_bawah,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.jari_tangan"
                            type="text"
                            class="form-control"
                            placeholder="Jari Tangan"
                            :class="{
                              'is-invalid': formValidate.jari_tangan,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.kuku_tangan"
                            type="text"
                            class="form-control"
                            placeholder="Kuku Tangan"
                            :class="{
                              'is-invalid': formValidate.kuku_tangan,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.persendian_tangan"
                            type="text"
                            class="form-control"
                            placeholder="Persendian Tangan"
                            :class="{
                              'is-invalid': formValidate.persendian_tangan,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.tungkai_atas"
                            type="text"
                            class="form-control"
                            placeholder="Tungkai Atas"
                            :class="{
                              'is-invalid': formValidate.tungkai_atas,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.tungkai_bawah"
                            type="text"
                            class="form-control"
                            placeholder="Tungkai Bawah"
                            :class="{
                              'is-invalid': formValidate.tungkai_bawah,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.jari_kaki"
                            type="text"
                            class="form-control"
                            placeholder="Jari Kaki"
                            :class="{
                              'is-invalid': formValidate.jari_kaki,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.kuku_kaki"
                            type="text"
                            class="form-control"
                            placeholder="Kuku Kaki"
                            :class="{
                              'is-invalid': formValidate.kuku_kaki,
                            }"
                          />
                        </div>
                        <div class="col-md-3 mb-2">
                          <input
                            v-model="form.persendian_kaki"
                            type="text"
                            class="form-control"
                            placeholder="Persendian Kaki"
                            :class="{
                              'is-invalid': formValidate.persendian_kaki,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card" v-if="this.userAccess == 'igd'">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(3)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              Screening
            </div>
            <div :class="{ show: openAccordion === 3 }" class="collapse">
              <div class="card-body">
                <div class="container"></div>
              </div>
            </div>
          </div>
          <div class="card">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(4)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              Pemeriksaan Psikologis, Sosial Ekonomi, Spiritual
            </div>
            <div :class="{ show: openAccordion === 4 }" class="collapse">
              <div class="card-body">
                <div class="container">
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="status_psikologis"
                      >Status Psikologis</label
                    >
                    <div class="col-md-9">
                      <input
                        list="listPsikologi"
                        v-model="form.status_psikologis"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.status_psikologis,
                        }"
                      />
                      <datalist id="listPsikologi">
                        <option value="">Pilih</option>
                        <option value="Tidak ada kelainan">
                          Tidak ada kelainan
                        </option>
                        <option value="Cemas">Cemas</option>
                        <option value="Takut">Takut</option>
                        <option value="Marah">Marah</option>
                        <option value="Sedih">Sedih</option>
                      </datalist>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="sosial_ekonomi"
                      >Sosial Ekonomi
                    </label>
                    <div class="col-md-9">
                      <input
                        v-model="form.sosial_ekonomi"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.sosial_ekonomi,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="spiritual"
                      >Spiritual</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.spiritual"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.spiritual,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(5)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              Pemeriksaan Spesialistik
            </div>
            <div :class="{ show: openAccordion === 5 }" class="collapse">
              <div class="card-body">
                <div class="container">
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="rencana_pemulangan_pasien"
                      >Riwayat Penggunaan Obat</label
                    >
                    <div class="col-md-9">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <th class="text-center align-middle">Nama Obat</th>
                            <th
                              class="text-center align-middle"
                              style="width: 150px"
                            >
                              Dosis
                            </th>
                            <th
                              class="text-center align-middle"
                              style="width: 200px"
                            >
                              Waktu Penggunaan
                            </th>
                            <th
                              class="text-center align-middle"
                              style="width: 50px"
                            >
                              <button
                                class="btn btn-info btn-sm"
                                @click="tambahBaris"
                              >
                                <i class="fa fa-plus"></i>
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in riwayat_obat"
                            :key="index"
                          >
                            <td>
                              <input
                                type="text"
                                v-model="item.nama_obat"
                                class="form-control m-b-5"
                                :class="{
                                  'is-invalid': formValidate.nama_obat,
                                }"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                v-model="item.dosis"
                                class="form-control m-b-5"
                                :class="{
                                  'is-invalid': formValidate.dosis,
                                }"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                v-model="item.waktu_penggunaan"
                                class="form-control m-b-5"
                                :class="{
                                  'is-invalid': formValidate.waktu_penggunaan,
                                }"
                              />
                            </td>
                            <td>
                              <a
                                href="javascript:;"
                                class="btn btn-circle btn-danger btn-sm"
                                @click="hapusBaris(index)"
                              >
                                <i class="fa fa-times"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="rencana_pemulangan_pasien"
                      >Perencanaan Pemulangan Pasien</label
                    >
                    <div class="col-md-9">
                      <select
                        v-model="form.rencana_pemulangan_pasien"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.rencana_pemulangan_pasien,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option value="Pasien lansia">Pasien lansia</option>
                        <option value="Gangguan anggota gerak">
                          Gangguan anggota gerak
                        </option>
                        <option
                          value="Pasien dengan perawatan berkelanjutan atau panjang"
                        >
                          Pasien dengan perawatan berkelanjutan atau panjang
                        </option>
                        <option
                          value="Memerlukan bantuan dalam aktivitas sehari-hari"
                        >
                          Memerlukan bantuan dalam aktivitas sehari-hari
                        </option>
                        <option value="Tidak masuk kriteria">
                          Tidak masuk kriteria
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="rencana_rawat"
                      >Rencana Rawat</label
                    >
                    <div class="col-md-9">
                      <textarea
                        v-model="form.rencana_rawat"
                        class="form-control m-b-5"
                        rows="4"
                        :class="{
                          'is-invalid': formValidate.rencana_rawat,
                        }"
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="instruksi_medik"
                      >Instruksi Medik dan Keperawatan</label
                    >
                    <div class="col-md-9">
                      <textarea
                        v-model="form.instruksi_medik"
                        class="form-control m-b-5"
                        rows="4"
                        :class="{
                          'is-invalid': formValidate.instruksi_medik,
                        }"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(6)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              Pemeriksaan Penunjang
            </div>
            <div :class="{ show: openAccordion === 6 }" class="collapse">
              <div class="card-body">
                <div class="container"></div>
              </div>
            </div>
          </div>
          <div class="card">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(7)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              Diagnosis
            </div>
            <div :class="{ show: openAccordion === 7 }" class="collapse">
              <div class="card-body">
                <div class="container">
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="status_psikologis"
                      >Diagnosis Awal / Masuk</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="search.diagnosis_awal"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.diagnosis_awal,
                        }"
                        placeholder="Masukkan Diagnosa/Kode ICD 10"
                        @input="handleDiagnosa(1)"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15" v-if="showDiagnosaAwal">
                    <label class="col-form-label col-md-3"></label>
                    <div class="col-md-9">
                      <table style="width: 100%">
                        <tr
                          v-for="icd in rows.icd"
                          v-bind:key="icd.code"
                          @click="setDiagnosa(1, icd.code, icd.name_id)"
                        >
                          <td>
                            <a
                              class="btn btn-sm btn-block btn-default"
                              style="text-align: left"
                              href="javascript:;"
                              >{{ icd.code }} : {{ icd.name_id }}</a
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="sosial_ekonomi"
                      >Diagnosis Primer
                    </label>
                    <div class="col-md-9">
                      <input
                        v-model="search.diagnosis_primer"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.diagnosis_primer,
                        }"
                        placeholder="Masukkan Diagnosa/Kode ICD 10"
                        @input="handleDiagnosa(2)"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15" v-if="showDiagnosaPrimer">
                    <label class="col-form-label col-md-3"></label>
                    <div class="col-md-9">
                      <table style="width: 100%">
                        <tr
                          v-for="icd in rows.icd"
                          v-bind:key="icd.code"
                          @click="setDiagnosa(2, icd.code, icd.name_id)"
                        >
                          <td>
                            <a
                              class="btn btn-sm btn-block btn-default"
                              style="text-align: left"
                              href="javascript:;"
                              >{{ icd.code }} : {{ icd.name_id }}</a
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="spiritual"
                      >Diagnosis Sekunder</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="search.diagnosis_sekunder"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.diagnosis_sekunder,
                        }"
                        placeholder="Masukkan Diagnosa/Kode ICD 10"
                        @input="handleDiagnosa(3)"
                      />
                    </div>
                  </div>

                  <div
                    class="form-group row m-b-15"
                    v-if="showDiagnosaSekunder"
                  >
                    <label class="col-form-label col-md-3"></label>
                    <div class="col-md-9">
                      <table style="width: 100%">
                        <tr
                          v-for="icd in rows.icd"
                          v-bind:key="icd.code"
                          @click="setDiagnosa(3, icd.code, icd.name_id)"
                        >
                          <td>
                            <a
                              class="btn btn-sm btn-block btn-default"
                              style="text-align: left"
                              href="javascript:;"
                              >{{ icd.code }} : {{ icd.name_id }}</a
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(8)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              Persetujuan Tindakan / Penolakan Tindakan (Informed Consent)
            </div>
            <div :class="{ show: openAccordion === 8 }" class="collapse">
              <div class="card-body">
                <div class="container">
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="status_psikologis"
                      >Diagnosis Awal / Masuk</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="search.diagnosis_awal"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.diagnosis_awal,
                        }"
                        placeholder="Masukkan Diagnosa/Kode ICD 10"
                        @input="handleDiagnosa(1)"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15" v-if="showDiagnosaAwal">
                    <label class="col-form-label col-md-4"></label>
                    <div class="col-md-9">
                      <table style="width: 100%">
                        <tr
                          v-for="icd in rows.icd"
                          v-bind:key="icd.code"
                          @click="setDiagnosa(1, icd.code, icd.name_id)"
                        >
                          <td>
                            <a
                              class="btn btn-sm btn-block btn-default"
                              style="text-align: left"
                              href="javascript:;"
                              >{{ icd.code }} : {{ icd.name_id }}</a
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="sosial_ekonomi"
                      >Diagnosis Primer
                    </label>
                    <div class="col-md-9">
                      <input
                        v-model="search.diagnosis_primer"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.diagnosis_primer,
                        }"
                        placeholder="Masukkan Diagnosa/Kode ICD 10"
                        @input="handleDiagnosa(2)"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15" v-if="showDiagnosaPrimer">
                    <label class="col-form-label col-md-4"></label>
                    <div class="col-md-9">
                      <table style="width: 100%">
                        <tr
                          v-for="icd in rows.icd"
                          v-bind:key="icd.code"
                          @click="setDiagnosa(2, icd.code, icd.name_id)"
                        >
                          <td>
                            <a
                              class="btn btn-sm btn-block btn-default"
                              style="text-align: left"
                              href="javascript:;"
                              >{{ icd.code }} : {{ icd.name_id }}</a
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="spiritual"
                      >Diagnosis Sekunder</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="search.diagnosis_sekunder"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.diagnosis_sekunder,
                        }"
                        placeholder="Masukkan Diagnosa/Kode ICD 10"
                        @input="handleDiagnosa(3)"
                      />
                    </div>
                  </div>

                  <div
                    class="form-group row m-b-15"
                    v-if="showDiagnosaSekunder"
                  >
                    <label class="col-form-label col-md-4"></label>
                    <div class="col-md-9">
                      <table style="width: 100%">
                        <tr
                          v-for="icd in rows.icd"
                          v-bind:key="icd.code"
                          @click="setDiagnosa(3, icd.code, icd.name_id)"
                        >
                          <td>
                            <a
                              class="btn btn-sm btn-block btn-default"
                              style="text-align: left"
                              href="javascript:;"
                              >{{ icd.code }} : {{ icd.name_id }}</a
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(9)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              Tindakan
            </div>
            <div :class="{ show: openAccordion === 9 }" class="collapse">
              <div class="card-body">
                <div class="container">
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="nama_tindakan"
                      >Nama Tindakan</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.nama_tindakan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.nama_tindakan,
                        }"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="petugas_pelaksana"
                      >Petugas yang Melaksanakan</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.petugas_pelaksana"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.petugas_pelaksana,
                        }"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="tgl_tindakan"
                      >Tanggal Pelaksanaan Tindakan</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.tgl_tindakan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.tgl_tindakan,
                        }"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label class="col-md-3 col-form-label push-right"
                      >Waktu Tindakan</label
                    >
                    <div class="col-md-9">
                      <div class="input-group">
                        <input
                          v-model="form.jam_mulai_tindakan"
                          type="time"
                          class="form-control"
                          :class="{
                            'is-invalid': formValidate.jam_mulai_tindakan,
                          }"
                        />
                        <span class="input-group-append">
                          <span class="input-group-text">-</span>
                        </span>
                        <input
                          v-model="form.jam_selesai_tindakan"
                          type="time"
                          class="form-control"
                          :class="{
                            'is-invalid': formValidate.jam_selesai_tindakan,
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="alat_medis_digunakan"
                      >Alat Medis yang Digunakan</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.alat_medis_digunakan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.alat_medis_digunakan,
                        }"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label class="col-md-3 col-form-label push-right" for="bmhp"
                      >BMHP</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.bmhp"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.bmhp,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div
              class="card-header pointer-cursor d-flex align-items-center"
              @click="toggleAccordion(10)"
            >
              <i class="fa fa-circle fa-fw text-blue mr-2 f-s-8"></i>
              Peresepan
            </div>
            <div :class="{ show: openAccordion === 10 }" class="collapse">
              <div class="card-body">
                <div class="container">
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="id_resep"
                      >ID Resep</label
                    >
                    <div class="col-md-9">
                      <input
                        v-model="form.id_resep"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.id_resep,
                        }"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="terapi_obat"
                      >Obat</label
                    >
                    <div class="col-md-9">
                      <div
                        class="row"
                        v-for="(item, index) in riwayat_obat"
                        :key="index"
                      >
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-8">
                              <input
                                v-model="form.nama_obat"
                                class="form-control m-b-5"
                                placeholder="Nama Obat"
                              />
                            </div>
                            <div class="col-md-4">
                              <input
                                v-model="form.nama_obat"
                                class="form-control m-b-5"
                                placeholder="ID Obat"
                              />
                            </div>
                            <div class="col-md-4">
                              <input
                                v-model="form.nama_obat"
                                class="form-control m-b-5"
                                placeholder="Bentuk / Sediaan"
                              />
                            </div>
                            <div class="col-md-3">
                              <input
                                v-model="form.nama_obat"
                                class="form-control m-b-5"
                                placeholder="Jumlah Obat"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.nama_obat"
                                class="form-control m-b-5"
                                placeholder="Metode / Rute Pemberian"
                              />
                            </div>
                            <div class="col-md-4">
                              <input
                                v-model="form.nama_obat"
                                class="form-control m-b-5"
                                placeholder="Dosis Obat"
                              />
                            </div>
                            <div class="col-md-3">
                              <input
                                v-model="form.nama_obat"
                                class="form-control m-b-5"
                                placeholder="Unit"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.nama_obat"
                                class="form-control m-b-5"
                                placeholder="Frekuensi / Interval"
                              />
                            </div>
                            <div class="col-md-10">
                              <input
                                v-model="form.nama_obat"
                                class="form-control m-b-5"
                                placeholder="Aturan Tambahan"
                              />
                            </div>
                            <div class="col-md-2 mb-3">
                              <a
                                href="javascript:;"
                                class="btn btn-block bg-red-lighter"
                                @click="hapusBaris(index)"
                              >
                                <i class="fa fa-times"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <button
                            class="btn btn-info btn-block"
                            @click="tambahBaris"
                          >
                            Tambah Obat
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label class="col-md-3 col-form-label push-right"
                      >Catatan Resep</label
                    >
                    <div class="col-md-9">
                      <textarea
                        v-model="form.catatan_resep"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.catatan_resep,
                        }"
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group row m-b-15">
                    <label class="col-md-3 col-form-label push-right"
                      >Dokter Penulis Resep</label
                    >
                    <div class="col-md-9">
                      <input
                        type="text"
                        v-model="form.catatan_resep"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.catatan_resep,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-teal btn-block mt-3"
          @click="postData()"
          :disabled="this.loading"
        >
          <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
          {{ $route.params.id ? "Simpan" : "Simpan" }}
        </button>
      </form>
    </div>
  </div>
</template>

<style>
.pointer-cursor:hover {
  cursor: pointer;
}
.page-link:hover {
  cursor: pointer;
}
</style>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      openAccordion: 5,
      form: {
        id_antrian: "",
        keluhan_utama: "",
        riwayat_penyakit: "",
        riwayat_alergi: "",
        riwayat_pengobatan: "",

        skala_nyeri: "",

        keadaaan_umum: "",
        tingkat_kesadaran: "",
        denyut_jantung: "",
        pernapasan: "",
        sistole: "",
        diastole: "",
        suhu_tubuh: "",
        kepala: "",
        mata: "",
        telinga: "",
        hidung: "",
        rambut: "",
        bibir: "",
        gigi_geligi: "",
        lidah: "",
        langit_langit: "",
        leher: "",
        tenggorokan: "",
        tonsil: "",
        dada: "",
        payudara: "",
        punggung: "",
        perut: "",
        genital: "",
        anus_dubur: "",
        lengan_atas: "",
        lengan_bawah: "",
        jari_tangan: "",
        kuku_tangan: "",
        persendian_tangan: "",
        tungkai_atas: "",
        tungkai_bawah: "",
        jari_kaki: "",
        kuku_kaki: "",
        persendian_kaki: "",

        nama_obat: "",
        dosis: "",
        waktu_penggunaan: "",
      },
      detail: {
        nama: "",
        nik: "",
        kode_register: "",
        kode_faskes: "",
        no_rekam_medik: "",
        tanggal: "",
        jam: "",
      },
      search: {
        icd: "",
        pasien: "",
        gejala: "",
      },
      rows: {
        icd: [],
        pasien: [],
        gejala: [],
      },
      riwayat_obat: [],
      link: "/v1/register/create",
      formValidate: [],
      loading: false,
      showUserAccess: true,
      timeoutId: null,
      showDiagnosaAwal: false,
      showDiagnosaPrimer: false,
      showDiagnosaSekunder: false,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.link = "/v1/register/update";
      this.form.id_antrian = id;
      this.fetchData();
      this.setProses();
    } else {
      this.form.id_antrian = "";
    }
  },
  methods: {
    postData() {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/v1/register/detail/" + this.form.id_antrian)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          // console.log(response.data.data);
          // this.detail.nik = response.data.data["nik"];
          // this.detail.nama = response.data.data["nama_pasien"];
          // this.detail.tanggal = response.data.data["tanggal"];
          // this.detail.jam = response.data.data["jam"];
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setProses() {
      axios
        .get("/v1/antrian/proses/" + this.form.id_antrian)
        .then((response) => {
          console.log(response.data.status);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setTunda() {
      axios
        .get("/v1/antrian/tunda/" + this.form.id_antrian)
        .then((response) => {
          console.log(response.data.status);
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setLab() {
      axios
        .get("/v1/antrian/lab/" + this.form.id_antrian)
        .then((response) => {
          console.log(response.data.status);
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    formatIndo(dateTime) {
      return moment(dateTime).format("DD MMMM YYYY");
    },
    toggleAccordion(index) {
      if (this.openAccordion === index) {
        this.openAccordion = null;
      } else {
        this.openAccordion = index;
      }
    },
    setNyeri(index) {
      if (this.form.skala_nyeri === index) {
        this.form.skala_nyeri = null;
      } else {
        this.form.skala_nyeri = index;
      }
    },
    handleDiagnosa(param) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.searchICD(param);
      }, 500);
    },
    searchICD(param) {
      const formData = new FormData();
      if (param == 1) {
        this.showDiagnosaAwal = true;
        formData.append("search", this.search.diagnosis_awal);
      } else if (param == 2) {
        this.showDiagnosaPrimer = true;
        formData.append("search", this.search.diagnosis_primer);
      } else {
        this.showDiagnosaSekunder = true;
        formData.append("search", this.search.diagnosis_sekunder);
      }

      axios
        .post("/v1/icdten/search/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows.icd = response.data.data || [];
          if (this.rows.icd.length <= 0) {
            this.showDiagnosaAwal = false;
            this.showDiagnosaPrimer = false;
            this.showDiagnosaSekunder = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setDiagnosa(param, kode, diagnosa) {
      if (param == 1) {
        this.showDiagnosaAwal = false;
        this.form.diagnosis_awal = kode;
        this.search.diagnosis_awal = kode + " : " + diagnosa;
      } else if (param == 2) {
        this.showDiagnosaPrimer = false;
        this.form.diagnosis_primer = kode;
        this.search.diagnosis_primer = kode + " : " + diagnosa;
      } else {
        this.showDiagnosaSekunder = false;
        this.form.diagnosis_sekunder = kode;
        this.search.diagnosis_sekunder = kode + " : " + diagnosa;
      }
      this.rows.icd = [];
    },
    tambahBaris() {
      this.riwayat_obat.push({
        nama_obat: "",
        dosis: "",
        waktu_penggunaan: "",
      });
    },
    hapusBaris(index) {
      this.riwayat_obat.splice(index, 1);
    },
  },
};
</script>